import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Parse from "parse";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// import Index from "./views/Index.jsx";
import Login from "./views/Login";
import Register from "./views/Register";
import Videos from "./views/Videos.jsx";
import VideoPage from "./views/VideoPage.jsx";
import PageNotFound from "./views/404";
import WatchLater from "./views/WatchLater";
import History from "./views/History";
import Unique from "./views/Unique";
// import Iframe from "./views/iframe";

Parse.serverURL = "https://api.shudhdesiporn.com/parse";
// Parse.serverURL = "http://api.shudhdesiporn.com:1337/parse";
// Parse.serverURL = process.env.serverURL || "http://localhost:1337/parse";
// Parse.serverURL = "https://sdp-api.arm.shudhdesiporn.com/parse";
// Parse.serverURL = "https://sdp-parse-api.herokuapp.com/";
Parse.initialize("shudhdesiporn007", "ShudhDesiP0rn007");

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/video/:slug" render={(props) => <VideoPage {...props} />} />
      <Route
        path="/videos/page/:page"
        render={(props) => <Videos {...props} />}
      />
      <Route path="/login" render={(props) => <Login {...props} />} exact />
      <Route
        path="/register"
        render={(props) => <Register {...props} />}
        exact
      />
      <Route
        path="/saved/page/:page"
        render={(props) => <WatchLater {...props} />}
        exact
      />
      <Route
        path="/history/page/:page"
        render={(props) => <History {...props} />}
        exact
      />
      <Route path="/" render={(props) => <Videos {...props} />} exact />
      <Route path="/unique" render={(props) => <Unique {...props} />} exact />
      {/* <Route path="/iframe" render={(props) => <Iframe {...props} />} exact /> */}

      <Route render={(props) => <PageNotFound {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
