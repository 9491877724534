import React, { Component } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

class WatchLater extends Component {
  state = {
    videos: [],
    sortBy: "updatedAt",
    page: 1,
    // search: "",
    // searchQuery: "",
    foundVideosCount: 0,
    savedCardsArray: [],
    isLoggedIn: false,
    saved: []
  };
  async componentDidMount() {
    await this.setState({
      page: this.props.match.params.page
    });
    // const query = new URLSearchParams(this.props.location.search);
    // if (query.get("search")) {
    // await this.setState({ searchQuery: query.get("search"), search: query });
    // }
    await this.getUserInfo();
    this.getVideos();
    if (this.state.saved.length <= 16) {
      document.getElementById("pagination").style.display = "none";
    }
    // console.log(search);
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sortBy !== this.state.sortBy ||
      prevState.page !== this.state.page
    ) {
      this.getVideos();
    }
    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.setState({ page: this.props.match.params.page });
    }
    if (prevState.saved !== this.state.saved) {
      this.getVideos();
    }

    // const oldQuery = new URLSearchParams(prevProps.location.search);
    // const newQuery = new URLSearchParams(this.props.location.search);
    // console.log(`Old: ${oldQuery}, new: ${newQuery}`);
    // if (oldQuery.get("search") !== newQuery.get("search")) {
    //   await this.setState({
    //     searchQuery: newQuery.get("search"),
    //     search: newQuery
    //   });
    //   this.getVideos();
    // }
  }
  // search = async searchQuery => {
  //   await this.setState({ searchQuery, page: 1 });
  //   this.getVideos();
  // };
  getUserInfo = async () => {
    if (Parse.User.current()) {
      let user = Parse.User.current();
      let saved = user.get("saved");
      await this.setState({
        isLoggedIn: true,
        name: user.get("name")
      });
      console.log(saved);
      if (saved) {
        await this.setState({
          saved
        });
      }
    }
  };
  paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };
  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };
  getVideos = async () => {
    // console.log(`getVideos: ${this.state.page}, ${this.state.searchQuery}`);
    let { saved, page } = this.state;
    let savedCardsArray = [];
    // console.log(saved);
    const Videos = Parse.Object.extend("Videos");
    const VideosQuery = new Parse.Query(Videos);
    if (saved) {
      let paginatedsaved = this.paginate(saved, 16, page);
      await this.asyncForEach(paginatedsaved, async videoId => {
        let video = await VideosQuery.get(videoId);
        savedCardsArray.push(video);
      });
      this.setState({
        savedCardsArray,
        foundVideosCount: savedCardsArray.length
      });
    }
    //thumbrotation
    let el = document.querySelectorAll(".thumb");
    el.forEach(e => {
      e.setAttribute(
        "onmouseover",
        `if (!window.__cfRLUnblockHandlers) return false; if (!window.__cfRLUnblockHandlers) return false; xvideosRotation.start(this)`
      );
      e.setAttribute(
        "onmouseout",
        `if (!window.__cfRLUnblockHandlers) return false; if (!window.__cfRLUnblockHandlers) return false; xvideosRotation.end(this)`
      );
    });
  };
  removeVideo = async videoId => {
    let user = Parse.User.current();
    let { saved } = this.state;
    for (var i = 0; i < saved.length; i++) {
      if (saved[i] === videoId) {
        saved.splice(i, 1);
      }
    }
    user.set("saved", saved);
    await user.save();
    this.getVideos();
  };
  renderVideoCards = () => {
    let { savedCardsArray } = this.state;
    let videoCards = [];
    let views = "";
    // if (savedCardsArray) {
    savedCardsArray.forEach(video => {
      // console.log()
      if (video.attributes.postViews >= 1000) {
        views = (video.attributes.postViews / 1000).toFixed(2) + "K";
      } else if (video.attributes.postViews >= 1000000) {
        views = (video.attributes.postViews / 1000000).toFixed(2) + "M";
      } else {
        views = video.attributes.postViews;
      }
      videoCards.push(
        <div key={video.id} className="col-xl-3 col-sm-6 mb-3">
          <div className="video-card">
            <div className="video-card-image">
              <a
                className="video-close"
                href="##"
                onClick={() => {
                  this.removeVideo(video.id);
                }}
              >
                <i className="fas fa-times-circle"></i>
              </a>
              <Link
                to={{ pathname: `/video/${video.attributes.slug}` }}
                className="play-icon"
              >
                <i className="fas fa-play-circle" />
              </Link>
              <Link to={{ pathname: `/video/${video.attributes.slug}` }}>
                <img
                  className="img-fluid thumb"
                  src={video.attributes.thumb}
                  alt=""
                />
              </Link>
              <div className="time">3:50</div>
            </div>
            <div className="video-card-body">
              <div className="video-title" style={{ height: "65px" }}>
                <Link to={{ pathname: `/video/${video.attributes.slug}` }}>
                  {video.attributes.title.substring(0, 85)}
                </Link>
              </div>
              {/* <div className="video-page text-success">
                Education{" "}
                <a
                  title=""
                  data-placement="top"
                  data-toggle="tooltip"
                  href="##"
                  data-original-title="Verified"
                >
                  <i className="fas fa-check-circle text-success" />
                </a>
              </div> */}
              <div className="video-view">
                {views} views &nbsp;
                <i className="fas fa-calendar-alt" />{" "}
                {video.updatedAt.toISOString().split("T")[0]}
              </div>
            </div>
          </div>
        </div>
      );
    });
    // }
    let heading;

    if (this.state.sortBy === "updatedAt") {
      heading = <h6>Recent Videos</h6>;
    } else if (this.state.sortBy === "postViews") {
      heading = <h6>Most Viewed Videos</h6>;
    } else {
      heading = <h6>Most Liked Videos</h6>;
    }

    if (!this.state.isLoggedIn) {
      return <h1 className="text-center">Please login to view saved items</h1>;
    } else if (this.state.savedCardsArray.length === 0) {
      return (
        <>
          <h1 className="text-center">
            Looks like you are new to Shudh Desi Porn
          </h1>{" "}
          <p className="text-center">
            Save any videos after registering/logging in. And they will show up
            here.
          </p>
        </>
      );
    }
    return (
      <>
        <div className="video-block section-padding">
          <div className="row">
            <div className="col-md-12">
              <div className="main-title">
                {/* <div className="btn-group float-right right-action">
                  <a
                    href="##"
                    className="right-action-link text-gray"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Sort by{" "}
                    <i className="fa fa-caret-down" aria-hidden="true" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => {
                        this.setState({ sortBy: "votesCount" });
                      }}
                    >
                      <i className="fas fa-fw fa-star" /> &nbsp; Top Rated
                    </a>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => {
                        this.setState({ sortBy: "postViews" });
                      }}
                    >
                      <i className="fas fa-fw fa-signal" /> &nbsp; Viewed
                    </a>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => {
                        this.setState({ sortBy: "updatedAt" });
                      }}
                    >
                      <i className="fas fa-fw fa-clock" /> &nbsp; Recent
                    </a>
                  </div>
                </div> */}
                {heading}
              </div>
            </div>
            {videoCards}
          </div>
        </div>
      </>
    );
  };
  toTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  pagination = () => {
    let { page, foundVideosCount } = this.state;
    let prevPage = parseFloat(page) - parseFloat(1);
    let nextPage = parseFloat(page) + parseFloat(1);
    let prevElement;
    let firstElement;
    let secondElement;
    let thirdElement;
    let nextElement;
    // console.log(page);
    if (parseFloat(page) === 1 || page === undefined) {
      prevElement = (
        <li className="page-item disabled">
          <Link
            to={{
              pathname: `/saved/page/1`
              // search: `${search}`
            }}
            className="page-link"
          >
            {" "}
            Previous{" "}
          </Link>
        </li>
      );
      firstElement = (
        <li className="page-item active">
          <Link
            to={{
              pathname: `/saved/page/1`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            1{" "}
          </Link>
        </li>
      );
      secondElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/2`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            2{" "}
          </Link>
        </li>
      );
      thirdElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/3`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            3{" "}
          </Link>
        </li>
      );
      nextElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/2`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            Next{" "}
          </Link>
        </li>
      );
    } else if (
      parseFloat(page) === parseFloat(Math.ceil(foundVideosCount / 16))
    ) {
      prevElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/${prevPage}`
              // search: `${search}`
            }}
            className="page-link"
          >
            {" "}
            Previous{" "}
          </Link>
        </li>
      );
      firstElement = (
        <li className="page-item ">
          <Link
            to={{
              pathname: `/saved/page/${prevPage - 1}`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            {prevPage - 1}{" "}
          </Link>
        </li>
      );
      secondElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/${prevPage}`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            {prevPage}{" "}
          </Link>
        </li>
      );
      thirdElement = (
        <li className="page-item active">
          <Link
            to={{
              pathname: `/saved/page/${page}`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            {page}{" "}
          </Link>
        </li>
      );
      nextElement = (
        <li className="page-item disabled">
          <Link to="/" className="page-link" onClick={this.toTop}>
            {" "}
            Next{" "}
          </Link>
        </li>
      );
    } else {
      prevElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/${prevPage}`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            Previous{" "}
          </Link>
        </li>
      );
      firstElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/${prevPage}`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            {prevPage}{" "}
          </Link>
        </li>
      );
      secondElement = (
        <li className="page-item active">
          <Link
            to={{
              pathname: `/saved/page/${page}`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            {page}{" "}
          </Link>
        </li>
      );
      thirdElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/${nextPage}`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            {nextPage}{" "}
          </Link>
        </li>
      );
      nextElement = (
        <li className="page-item">
          <Link
            to={{
              pathname: `/saved/page/${nextPage}`
              // search: `${search}`
            }}
            className="page-link"
            onClick={this.toTop}
          >
            {" "}
            Next{" "}
          </Link>
        </li>
      );
    }
    return (
      <nav id="pagination" aria-label="Page navigation">
        <ul className="pagination justify-content-center pagination-sm mb-4">
          {prevElement}
          {firstElement}
          {secondElement}
          {thirdElement}
          {nextElement}
        </ul>
      </nav>
    );
  };
  render() {
    return (
      <>
        <Navbar
          history={this.props.history}
          location={this.props.location}
          // searchQuery={this.state.searchQuery}
          // search={this.search}
        />
        <div id="wrapper">
          <Sidebar location={this.props.location} />
          <div id="content-wrapper">
            <div className="container-fluid pb-0">
              {this.renderVideoCards()}
            </div>
          </div>
        </div>
        {this.pagination()}
        <Footer />
      </>
    );
  }
}

export default WatchLater;
