import React, { Component } from "react";
import { Link } from "react-router-dom";
import Parse from "parse";

import userAvatar from "../assets/img/user.png";

class Navbar extends Component {
  state = {
    searchQuery: "",
    isLoggedIn: false,
    name: "Guest",
  };
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    // console.log(query.get("search"));
    if (query.get("search")) {
      this.setState({ searchQuery: query.get("search") });
    }
    this.getUserInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    const oldQuery = new URLSearchParams(prevProps.location.search);
    const newQuery = new URLSearchParams(this.props.location.search);
    if (oldQuery.get("search") !== newQuery.get("search")) {
      this.setState({ searchQuery: newQuery.get("search") });
    }
  }
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  handleSearch = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/videos/page/1`,
      search: `?search=${this.state.searchQuery}`,
    });
  };
  getUserInfo = () => {
    if (Parse.User.current()) {
      let user = Parse.User.current();
      this.setState({ isLoggedIn: true, name: user.get("name") });
    }
  };
  userDropDown = () => {
    if (this.state.isLoggedIn) {
      return (
        <>
          <Link to="account" className="dropdown-item">
            <i className="fas fa-fw fa-user-circle" /> &nbsp; My Account
          </Link>
          <Link to="subscriptions" className="dropdown-item">
            <i className="fas fa-fw fa-video" /> &nbsp; Subscriptions
          </Link>
          <Link to="settings" className="dropdown-item">
            <i className="fas fa-fw fa-cog" /> &nbsp; Settings
          </Link>
          <div className="dropdown-divider" />
          <Link
            to="#"
            className="dropdown-item"
            onClick={(e) => {
              e.preventDefault();
              Parse.User.logOut().then(
                this.setState({ isLoggedIn: false, name: "Guest" })
              );
            }}
            // data-toggle="modal"
            // data-target="#logoutModal"
          >
            <i className="fas fa-fw fa-sign-out-alt" /> &nbsp; Logout
          </Link>
        </>
      );
    } else {
      return (
        <>
          <Link to="/login" className="dropdown-item">
            <i className="fas fa-fw fa-user" /> &nbsp; Login
          </Link>
          <Link to="/register" className="dropdown-item">
            <i className="fas fa-fw fa-user-plus" /> &nbsp; Register
          </Link>
        </>
      );
    }
  };
  render() {
    return (
      <nav className="navbar navbar-expand navbar-light bg-white static-top osahan-nav sticky-top">
        &nbsp;&nbsp;
        <button
          className="btn btn-link btn-sm text-secondary order-1 order-sm-0"
          id="sidebarToggle"
          aria-label="Toggle Sidebar"
        >
          <i className="fas fa-bars" />
        </button>{" "}
        &nbsp;&nbsp;
        <Link to="/" className="navbar-brand mr-1">
          {/* <img className="img-fluid" alt="" src={logo} /> */}
          <span style={{ color: "gray" }}>ShudhDesi</span>
          <span style={{ color: "red" }}> Porn</span>
        </Link>
        {/* Navbar Search */}
        <form
          className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-5 my-2 my-md-0 osahan-navbar-search"
          onSubmit={this.handleSearch}
        >
          <div className="input-group">
            <input
              type="text"
              name="searchQuery"
              value={this.state.searchQuery}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Search for..."
            />
            <div className="input-group-append">
              <button className="btn btn-light" type="button">
                <i className="fas fa-search" />
              </button>
            </div>
          </div>
        </form>
        {/* Navbar */}
        <ul className="navbar-nav ml-auto ml-md-0 osahan-right-navbar">
          {/* <li className="nav-item mx-1">
            <Link to="upload" className="nav-link">
              <i className="fas fa-plus-circle fa-fw" />
              Upload Video
            </Link>
          </li> */}
          {/* <li className="nav-item dropdown no-arrow mx-1">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="alertsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-bell fa-fw" />
              <span className="badge badge-danger">9+</span>
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="alertsDropdown"
            >
              <Link to="#" className="dropdown-item">
                <i className="fas fa-fw fa-edit " /> &nbsp; Action
              </Link>
              <Link to="#" className="dropdown-item">
                <i className="fas fa-fw fa-headphones-alt " /> &nbsp; Another
                action
              </Link>
              <div className="dropdown-divider" />
              <Link to="#" className="dropdown-item">
                <i className="fas fa-fw fa-star " /> &nbsp; Something else here
              </Link>
            </div>
          </li> */}
          {/* <li className="nav-item dropdown no-arrow mx-1">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="messagesDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-envelope fa-fw" />
              <span className="badge badge-success">7</span>
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="messagesDropdown"
            >
              <Link to="#" className="dropdown-item">
                <i className="fas fa-fw fa-edit " /> &nbsp; Action
              </Link>
              <Link to="#" className="dropdown-item">
                <i className="fas fa-fw fa-headphones-alt " /> &nbsp; Another
                action
              </Link>
              <div className="dropdown-divider" />
              <Link to="#" className="dropdown-item">
                <i className="fas fa-fw fa-star " /> &nbsp; Something else here
              </Link>
            </div>
          </li> */}
          <li className="nav-item dropdown no-arrow osahan-right-navbar-user">
            <Link
              to="#"
              className="nav-link dropdown-toggle user-dropdown-link"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img alt="Avatar" src={userAvatar} />
              Hi {this.state.name}!
            </Link>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="userDropdown"
            >
              {this.userDropDown()}
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
