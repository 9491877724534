import React, { Component } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

class PageNotFound extends Component {
  state = {};

  render() {
    return (
      <>
        <Navbar history={this.props.history} location={this.props.location} />
        <div id="wrapper">
          <Sidebar
            history={this.props.history}
            location={this.props.location}
          />
          <div id="content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 mx-auto text-center  pt-4 pb-5">
                  <h1>
                    <img alt={404} src="img/404.png" className="img-fluid" />
                  </h1>
                  <h1>Oops!</h1>
                  <p className="land">
                  The video you are looking for was either deleted or you followed a broken link.
                  </p>
                  <div className="mt-5">
                    <Link
                      className="btn btn-outline-primary"
                      to={{ pathname: "/" }}
                    >
                      <i className="mdi mdi-home" /> GO TO HOME PAGE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PageNotFound;
