import React, { Component } from "react";
import { Link } from "react-router-dom";

class Sidebar extends Component {
  state = {};
  componentDidMount() {
    if (this.props.location.pathname.includes("/saved")) {
      document.getElementById("home").classList.remove("active");
      document.getElementById("saved").classList.add("active");
    } else if (this.props.location.pathname.includes("/history")) {
      document.getElementById("home").classList.remove("active");
      document.getElementById("history").classList.add("active");
    }
    let width = document.body.clientWidth;
    if (width <= 576) {
      document.querySelector(".sidebar.navbar-nav").classList.add("toggled");
      document.querySelector("body").classList.add("sidebar-toggled");
    }
  }
  render() {
    return (
      <>
        <ul className="sidebar navbar-nav">
          <li id="home" className="nav-item active">
            <Link className="nav-link" to="/">
              <i className="fas fa-fw fa-home" />
              <span>Home</span>
            </Link>
          </li>

          <li id="saved" className="nav-item">
            <Link className="nav-link" to="/saved/page/1">
              <i className="fas fa-fw fa-star" />
              <span>Watch Later</span>
            </Link>
          </li>
          <li id="history" className="nav-item">
            <Link className="nav-link" to="/history/page/1">
              <i className="fas fa-fw fa-history" />
              <span>History</span>
            </Link>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="##"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-fw fa-list-alt" />
              <span>Categories</span>
            </a>
            <div className="dropdown-menu">
              <Link
                className="dropdown-item"
                to={{ pathname: `/videos/page/1`, search: "?search=bhabhi" }}
              >
                Bhabhi
              </Link>
              <Link
                className="dropdown-item"
                to={{ pathname: `/videos/page/1`, search: "?search=teens" }}
              >
                Teens
              </Link>
              <Link
                className="dropdown-item"
                to={{
                  pathname: `/videos/page/1`,
                  search: "?search=web-series"
                }}
              >
                Web Series
              </Link>
              <Link
                className="dropdown-item"
                to={{ pathname: `/videos/page/1`, search: "?search=south" }}
              >
                South Masala
              </Link>
              <Link
                className="dropdown-item"
                to={{ pathname: `/videos/page/1`, search: "?search=north" }}
              >
                North Masala
              </Link>
              <Link
                className="dropdown-item"
                to={{ pathname: `/videos/page/1`, search: "?search=softcore" }}
              >
                Softcore
              </Link>
              <Link
                className="dropdown-item"
                to={{ pathname: `/videos/page/1`, search: "?search=navel" }}
              >
                Navel
              </Link>
            </div>
          </li>
          <li className="nav-item channel-sidebar-list">
            {/* <h6>SUBSCRIPTIONS</h6>
            <ul>
              <li>
                <a href="subscriptions">
                  <img className="img-fluid" alt="" src="img/s1.png" /> Your
                  Life
                </a>
              </li>
              <li>
                <a href="subscriptions">
                  <img className="img-fluid" alt="" src="img/s2.png" /> Unboxing{" "}
                  <span className="badge badge-warning">2</span>
                </a>
              </li>
              <li>
                <a href="subscriptions">
                  <img className="img-fluid" alt="" src="img/s3.png" /> Product
                  / Service
                </a>
              </li>
              <li>
                <a href="subscriptions">
                  <img className="img-fluid" alt="" src="img/s4.png" /> Gaming
                </a>
              </li>
            </ul> */}
          </li>
        </ul>
      </>
    );
  }
}

export default Sidebar;
