import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer className="sticky-footer">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 col-sm-6">
              <p className="mt-1 mb-0">
                © Copyright {new Date().getFullYear().toString()}{" "}
                <strong className="text-dark">Shudh Desi Porn</strong>. All
                Rights Reserved
                <br />
              </p>
            </div>
            {/* <div className="col-lg-6 col-sm-6 text-right">
              <div className="app">
                <a href="##">
                  <img alt="" src="img/google.png" />
                </a>
                <a href="##">
                  <img alt="" src="img/apple.png" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
