import React, { Component } from "react";
import { Link } from "react-router-dom";
import Parse from "parse";
import favicon from "../assets/img/favicon.png";

class Login extends Component {
  state = {
    email: "",
    password: "",
    warning: "none"
  };
  componentDidMount() {
    document.body.classList.add("login-main-body");
    this.getUserInfo();
  }
  getUserInfo = async () => {
    let user = Parse.User.current();
    if (user) {
      this.props.history.goBack();
    }
  };
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };
  handleLogin = e => {
    e.preventDefault();
    Parse.User.logIn(this.state.email, this.state.password)
      .then(() => {
        this.props.history.goBack();
      })
      .catch(error => {
        console.log(error.message);
        document.getElementById("loginWarning").innerHTML = error.message;
        document.getElementById("loginWarning").style.cssText =
          "display: block;color: rgba(255,255,255,.6);size: .8rem";
      });
  };
  render() {
    return (
      <>
        <section className="login-main-wrapper">
          <div className="container-fluid pl-0 pr-0">
            <div className="row no-gutters">
              <div className="col-md-5 p-5 bg-white full-height">
                <div className="login-main-left">
                  <div className="text-center mb-5 login-main-left-header pt-4">
                    <img src={favicon} className="img-fluid" alt="LOGO" />
                    <h5 className="mt-3 mb-3">Welcome to Shudh Desi Porn</h5>
                    <p>The best desi porn aggregator in the world.</p>
                  </div>
                  <form onSubmit={this.handleLogin}>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Enter email id"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Password"
                      />
                    </div>
                    <div
                      id="loginWarning"
                      className="text-center"
                      style={{ display: this.state.warning }}
                    >
                      Something went wrong
                    </div>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-outline-primary btn-block btn-lg"
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="text-center mt-5">
                    <p className="light-gray">
                      Don’t have an account? <Link to="register">Sign Up</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="login-main-right bg-white p-5 mt-4 mb-5">
                  <div className="carousel-login-card text-center">
                    <img
                      src="/img/login.jpg"
                      className="img-fluid"
                      alt="LOGO"
                    />
                    <h5 className="mt-5">​Shudh Desi Porn</h5>
                    {/* <p className="mb-4">
                      when an unknown printer took a galley of type and
                      scrambled
                      <br /> it to make a type specimen book. It has survived
                      not <br />
                      only five centuries
                    </p> */}
                  </div>
                  {/* <div className="owl-carousel owl-carousel-login"> */}
                  {/* <div className="item"> */}

                  {/* </div> */}
                  {/* <div className="item">
                      <div className="carousel-login-card text-center">
                        <img src={loginPNG} className="img-fluid" alt="LOGO" />
                        <h5 className="mt-5 mb-3">
                          Download videos effortlessly
                        </h5>
                        <p className="mb-4">
                          when an unknown printer took a galley of type and
                          scrambled
                          <br /> it to make a type specimen book. It has
                          survived not <br />
                          only five centuries
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="carousel-login-card text-center">
                        <img src={loginPNG} className="img-fluid" alt="LOGO" />
                        <h5 className="mt-5 mb-3">Create GIFs</h5>
                        <p className="mb-4">
                          when an unknown printer took a galley of type and
                          scrambled
                          <br /> it to make a type specimen book. It has
                          survived not <br />
                          only five centuries
                        </p>
                      </div>
                    </div> 
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Login;
