import React, { Component } from "react";
import Parse from "parse";
// import { Link } from "react-router-dom";

// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import Sidebar from "../components/Sidebar";

class Videos extends Component {
  state = {
    videos: [],
    sortBy: "date",
    page: 1,
    search: "",
    searchQuery: "",
    foundVideosCount: 0,
    saved: [],
  };
  async componentDidMount() {
    this.getAllVideos();
  }
  makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  getAllVideos = async () => {
    const Videos = Parse.Object.extend("Videos");
    const VideosQuery = new Parse.Query(Videos);
    VideosQuery.equalTo("status", "draft");
    let VideosArray = await VideosQuery.limit(1000).find();
    // console.log(VideosArray);
    VideosArray.forEach(async (video) => {
      const slugQuery = new Parse.Query(Videos);
      slugQuery.equalTo("slug", video.attributes.slug);
      const count = await slugQuery.count();
      if (count > 1) {
        console.log(video.attributes.slug);
        video.set("repeatedSlug", video.attributes.slug);
        video.set("slug", video.attributes.slug + this.makeid(5));
        await video.save();
      }
    });
  };

  render() {
    return <></>;
  }
}

export default Videos;
