import React, { Component } from "react";
import { Link } from "react-router-dom";
import Parse from "parse";
import favicon from "../assets/img/favicon.png";

class Register extends Component {
  state = {
    email: "",
    password: "",
    terms: false,
    warning: "none",
  };
  componentDidMount() {
    document.body.classList.add("login-main-body");
    this.getUserInfo();
  }
  getUserInfo = async () => {
    let user = Parse.User.current();
    if (user) {
      this.props.history.goBack();
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };
  handleSignUp = async (e) => {
    e.preventDefault();
    let referrer = localStorage.getItem("referrer");
    if (this.state.terms) {
      e.preventDefault();
      let userDetails = {
        username: this.state.email,
        email: this.state.email,
        name: this.state.name,
        password: this.state.password,
        usertype: "free",
        referrer,
      };
      let user = new Parse.User(userDetails);
      try {
        await user.signUp().then(() => {
          this.props.history.goBack();
        });
      } catch (error) {
        if (error.message === "Account already exists for this username.") {
          error.message = "Email is already in use. Please login.";
        }
        document.getElementById("signUpWarning").innerHTML = error.message;
        document.getElementById("signUpWarning").style.cssText =
          "display: block;color: rgba(255,255,255,.6);size: .8rem";
      }
    } else {
      document.getElementById("signUpWarning").style.cssText =
        "display: block;color: rgba(255,255,255,.6);size: .8rem";
    }
  };
  render() {
    return (
      <>
        <section className="login-main-wrapper">
          <div className="container-fluid pl-0 pr-0">
            <div className="row no-gutters">
              <div className="col-md-5 p-5 bg-white full-height">
                <div className="login-main-left">
                  <div className="text-center mb-5 login-main-left-header pt-4">
                    <img src={favicon} className="img-fluid" alt="LOGO" />
                    <h5 className="mt-3 mb-3">Welcome to Shudh Desi Porn</h5>
                    <p>The best desi porn aggregator in the world.</p>
                  </div>
                  <form onSubmit={this.handleSignUp}>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Enter email id"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Password"
                      />
                    </div>
                    <div className="custom-control custom-checkbox mr-sm-2">
                      <input
                        name="terms"
                        onChange={this.handleInputChange}
                        type="checkbox"
                        className="custom-control-input"
                        id="customControlAutosizing"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customControlAutosizing"
                      >
                        <span className="form-check-sign" />I agree to the{" "}
                        <a href="/terms-and-conditions" target="_blank">
                          terms and conditions
                        </a>
                        .
                      </label>
                    </div>

                    <div
                      id="signUpWarning"
                      className="text-center"
                      style={{ display: this.state.warning }}
                    >
                      Something went wrong
                    </div>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-outline-primary btn-block btn-lg"
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="text-center mt-5">
                    <p className="light-gray">
                      Already have an Account? <Link to="login">Sign In</Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="login-main-right bg-white p-5 mt-4 mb-5">
                  <div className="carousel-login-card text-center">
                    <img
                      src="/img/login.jpg"
                      className="img-fluid"
                      alt="LOGO"
                    />
                    <h5 className="mt-5">​Shudh Desi Porn</h5>
                    {/* <p className="mb-4">
                      when an unknown printer took a galley of type and
                      scrambled
                      <br /> it to make a type specimen book. It has survived
                      not <br />
                      only five centuries
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Register;
